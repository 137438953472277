import Breadcrumbs from "@/components/Breadcrumbs";
import PageSections from "@/components/PageSections";
import { PreviewContent } from "@/interfaces/PreviewContent.interface";
import craft from "@/lib/craft";
import { returnStaticProps } from "@/lib/returnStaticProps";
import QueryEntries from "@/queries/QueryEntries.graphql";
import QueryPage from "@/queries/QueryPage.graphql";
import QuerySlugs from "@/queries/QuerySlugs.graphql";
import clsx from "clsx";
import { GetStaticPaths, GetStaticProps } from "next";
import Head from "next/head";
import { Seomatic } from "react-seomatic";

export const getStaticPaths: GetStaticPaths = async (context) => {
  // Fetch every possible page path
  const response = await craft(QuerySlugs, { section: "pages" });

  const paths = [];

  response.entries.map((page) =>
    paths.push({
      params: { slug: page.uri.split("/") },
    }),
  );

  return { paths, fallback: "blocking" };
};

export const getStaticProps: GetStaticProps = async ({
  params,
  previewData: nextPreviewData,
  preview,
}) => {
  // For Craft preview capability
  const previewData: PreviewContent = nextPreviewData as PreviewContent;

  // Construct uri from the slug array
  const uri = (params.slug as string[]).join("/");

  // Fetch the page's data
  const response = await craft(
    QueryPage,
    { uri },
    preview ? previewData.token : null,
  );

  const { pageEntry, primaryNavigation, footerNavigation } = response;

  // Recursive function to process sections
  const processSections = async (sections) => {
    return Promise.all(
      sections.map(async (section) => {
        if (section.typeHandle === "entryCarousel") {
          const carouselResponse = await craft(QueryEntries, {
            section: section.latestEntriesFromSection,
          });

          return {
            ...section,
            entries: carouselResponse.entries,
          };
        }

        if (section.children && section.children.length > 0) {
          return {
            ...section,
            children: await processSections(section.children),
          };
        }

        return section;
      }),
    );
  };

  // Process page sections including nested children
  const processedSections = await processSections(pageEntry.pageSections);

  // Return props if entry exists, otherwise check Retour for redirects or 404
  const returnResponse = await returnStaticProps(
    {
      navigation: {
        primaryNavigation,
        footerNavigation,
      },
      pageEntry: {
        ...pageEntry,
        pageSections: processedSections,
      },
    },
    !pageEntry,
    uri,
  );

  return returnResponse;
};

export default function Page({ pageEntry }): JSX.Element {
  let breadcrumbs = [];

  if (pageEntry.level === 2) breadcrumbs = [pageEntry.parent, pageEntry];
  if (pageEntry.level === 3)
    breadcrumbs = [pageEntry.parent.parent, pageEntry.parent, pageEntry];

  return (
    <>
      <Seomatic Head={Head} {...pageEntry.seomatic} />
      {breadcrumbs.length > 0 && (
        <div className={clsx("Page__breadcrumbs", "mb-16 hidden")}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      )}
      {!!pageEntry.pageSections && (
        <PageSections sections={pageEntry.pageSections} />
      )}
    </>
  );
}
