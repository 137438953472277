import { getHref } from "@/lib/routes";
import clsx from "clsx";
import Link from "next/link";

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <div className={clsx("Breadcrumbs")}>
      <div
        className={clsx(
          "Breadcrumbs__content",
          "mx-auto max-w-screen-3xl px-16"
        )}
      >
        <div className={clsx("Breadcrumbs__breadcrumbs", "space-x-2")}>
          {breadcrumbs.map((breadcrumb) => {
            return (
              <Link
                key={breadcrumb.id}
                href={getHref(breadcrumb)}
                className={clsx(
                  "Breadcrumbs__link",
                  "text-gray",
                  "group",
                  "after:pl-2 after:content-['/'] last-of-type:after:hidden"
                )}
              >
                <span
                  className={clsx(
                    "text-gray group-hover:text-black",
                    "transition-colors"
                  )}
                >
                  {breadcrumb.title}
                </span>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
